<!-- 告警分析 -->
<template>
  <div id="alarm-analysis">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>告警分析</span>
      </div>
      <ul class="alarm-info">
        <li>
          <div class="circle">日</div>
          <div class="alarm-number">
            <div>今日报警数</div>
            <span>{{ alarmStatistic.day }}</span>
          </div>
          <div class="alarm-number">
            <div>昨日报警数</div>
            <span>{{ alarmStatistic.lastDay }}</span>
          </div>
          <div class="alarm-number">
            <div>环比</div>
            <span>{{ alarmStatistic.datRate }}%</span>
          </div>
        </li>
        <li>
          <div class="circle">月</div>
          <div class="alarm-number">
            <div>本月报警数</div>
            <span>{{ alarmStatistic.month }}</span>
          </div>
          <div class="alarm-number">
            <div>上月报警数</div>
            <span>{{ alarmStatistic.lastMonth }}</span>
          </div>
          <div class="alarm-number">
            <div>环比</div>
            <span>{{ alarmStatistic.monthRate }}%</span>
          </div>
        </li>
        <li>
          <div class="circle">年</div>
          <div class="alarm-number">
            <div>本年报警数</div>
            <span>{{ alarmStatistic.year }}</span>
          </div>
          <div class="alarm-number">
            <div>去年报警数</div>
            <span>{{ alarmStatistic.lastYear }}</span>
          </div>
          <div class="alarm-number">
            <div>环比</div>
            <span>{{ alarmStatistic.yeapRate }}%</span>
          </div>
        </li>
      </ul>
      <div class="content">
        <div class="content-left">
          <ul class="tabs">
            <li style="width: 105px" class="select">
              站点报警
            </li>
          </ul>
          <div class="query-form">
            <el-form class="form" ref="form" :inline="true" :model="requestParam">
              <el-form-item label="开始时间">
                <el-date-picker placeholder="请选择开始时间" type="date" value-format="yyyy-MM-dd" clearable
                  v-model="requestParam.createdAtStart" :append-to-body="false"></el-date-picker>
              </el-form-item>
              <el-form-item label="结束时间">
                <el-date-picker placeholder="请选择结束时间" type="date" value-format="yyyy-MM-dd"
                  v-model="requestParam.createdAtEnd" :append-to-body="false"></el-date-picker>
              </el-form-item>
            </el-form>
            <div class="query-button">
              <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
            </div>
          </div>
          <el-table class="table" :data="tableData">
            <div slot="empty">
              <div v-if="tableData.length == 0">
                <div class="empty-data-view">
                  <div class="table-text">
                    {{ loading ? "" : "暂无数据" }}
                  </div>
                </div>
              </div>
            </div>
            <!-- <el-table-column align="center" type="index" label="序号" width="75">
              <template slot-scope="scope">
                <span>{{
                  (requestParam.pageNum - 1) * requestParam.pageSize +
                  scope.$index +
                  1
                }}</span>
              </template>
            </el-table-column> -->

            <el-table-column align="center" prop="alarmLevel" label="告警级别">
            </el-table-column>
            <el-table-column align="center" prop="readRate" label="已读率">
            </el-table-column>
            <el-table-column align="center" prop="alarmNum" label="告警数量">
            </el-table-column>
          </el-table>
        </div>
        <div class="content-right">
          <ul class="tabs">
            <li v-for="(item, index) in alarmData" :key="index" style="width: 105px"
              :class="select === index ? 'select' : ''">
              {{ item }}
            </li>
          </ul>
          <div id="pieChart"></div>
         
          <div style="height: 500px;display: flex;justify-content: center;align-items: center;color: #aed6ff;" class="" v-show="aboutNull==2">
            <div class="" id="">暂无数据</div>
          </div>
        </div>
        
      </div>
      <div class="" style="display: flex;align-items: flex-start;">
            <div class="monthTitleText" style="margin-top: 20px;">平台月报警统计</div>
            <div class="monthTitleText">
              <el-form class="form" ref="form" :inline="true" :model="requestParam">
                <el-form-item>
                  <el-date-picker class="abcccc" placeholder="请选择开始时间" type="month"  clearable
                    v-model="monthTime" :append-to-body="false" @change="changeTimeMonth"></el-date-picker>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="" v-show="aboutNull==1">
            <div class="" id="monthCharts"></div>
          </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { alarmApi } from "@/api/alarm.js";
import { nextTick } from "vue";
export default {
  name: "",

  data() {
    return {
      tabsData: ["普通", "严重", "事故"],
      alarmData: ["站点报警图表"],
      select: 0,
      tableData: [ ],
      requestParam: {
        createdAtStart: '',
        createdAtEnd: '',
      },
      monthTime: new Date(),
      total: 0,
      stationId: "",
      type: "",
      alarmStatistic: {},
      loading: false,
      aboutNull:1,
    };
  },
  created() {
    this.$store.commit("increment", "事件记录");
    this.$store.commit("selectChild", "告警分析");
    this.$store.commit("selectChildren", "");
    this.stationId=sessionStorage.getItem("stationId")
  },
  mounted() {
    this.queryAlarmStatistic();
    this.getList()
    this.barCharts()
  },
  methods: {
    formQuery() {
      console.log(this.requestParam);
      this.getList()
    },
    changeTimeMonth() { this.barCharts()},
    getList() {
      let obj = {
        startDate:`${this.requestParam.createdAtStart } 00:00:00`,
        endDate:`${this.requestParam.createdAtEnd} 23:59:59`,
        id: this.stationId
      }
      // obj = {
      //   endDate: '2024-05-16 23:59:59',
      //   startDate: '2024-05-16 00:00:00',
      // obj.id= '22575'
      // }
      let currentDate = new Date();

        let year = currentDate.getFullYear();
        let month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // 补零并截取后两位
        let day = ('0' + currentDate.getDate()).slice(-2); // 补零并截取后两位
        if(!this.requestParam.createdAtStart){
          obj.startDate = `${year}-${month}-${day} 00:00:00`;
        }
        if(!this.requestParam.createdAtEnd){
          obj.endDate = `${year}-${month}-${day} 23:59:59`;
        }



      alarmApi.getAnalysisList(obj).then(res => {
        console.log(res);
        if (res.code == 200) {

          this.tableData = res.data
          let majorAlarm;
          let seriousAlarm;
          let averageAlarm;
          let specialAlarm;
          res.data.forEach(item => {
            if (item.alarmLevel == '特大告警') {
              specialAlarm = item.alarmNum
            }
            if (item.alarmLevel == '一般告警') {
              averageAlarm = item.alarmNum
            }
            if (item.alarmLevel == '严重告警') {
              seriousAlarm = item.alarmNum
            }
            if (item.alarmLevel == '重大告警') {
              majorAlarm = item.alarmNum
            }
          })
          console.log(seriousAlarm, averageAlarm, majorAlarm, specialAlarm);
          this.drawPie(averageAlarm, seriousAlarm, majorAlarm, specialAlarm)
        }

      })
    },
    queryAlarmStatistic() {
      let requestParam = {
        id: this.stationId,
      };
      alarmApi.queryAlarmStatistic(requestParam).then((res) => {
        if (res.code === 200) {
          this.alarmStatistic = res.data;
          // let btnAddBlackOutPlan= ras.data.本日报警数
        }
      });
    },
    drawPie(seriousAlarm, averageAlarm, majorAlarm, specialAlarm) {
      console.log(seriousAlarm, averageAlarm, majorAlarm, specialAlarm);
      var pieChart = echarts.init(document.getElementById("pieChart"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
            left: '2%',
            right: '3%',
            containLabel: true
          },
        tooltip: {
          trigger: "item",
        },
        label: {
          color: "#aed6ff",
          fontSize: 12,
        },
        legend: {
          show: true,
          bottom: "-2%",
          left: "center",
          textStyle: {
            color: "#aed6ff",
            fontSize: 12,
          },
        },
        color: ["#51A1FF", "#F6CD29", "#FF6A24", "#C92749"],
        series: [
          {
            name: "告警统计",
            type: "pie",
            radius: ['50%', '65%'],
            // center: ["45%", "50%"],
            data: [
              { value:seriousAlarm , name: "一般" },
              { value: averageAlarm, name: "严重" },
              { value: majorAlarm, name: "重大" },
              { value: specialAlarm, name: "特别重大" },
            ],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      pieChart.setOption(option);
    },
    barCharts() {

      let obj={
        id:this.stationId,
        month:'',
        year:'',
      }
      let d = new Date(this.monthTime)
      obj.year = d.getFullYear()
      obj.month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)
     
      alarmApi.getBarList(obj).then(res=>{
        if(res.data){
          this.aboutNull=1
        }else{
          this.aboutNull=2

        }
        let keys = Object.keys(res.data);    
        let values = Object.values(res.data);
        console.log("keys:", keys);
        console.log("values:", values);
        var pieChart = echarts.init(document.getElementById("monthCharts"));
        var option = {
  
          grid: {
            left: '2%',
            right: '3%',
            
            containLabel: true
          },
          backgroundColor: '#051637',
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['告警',],
            right: '30px',
            top: '16px',
            textStyle: {
              color: "#AED6FF"
            }
          },
          color: ['#2FC9CB'],
          toolbox: {
            show: true,
  
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
  
            data: keys,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              textStyle: {
                color: '#aed6ff' // 设置 x 轴标签字体颜色
              }
            }
          },
          yAxis: {
            type: 'value',
            name: "单位/条",
            nameTextStyle: {
              color: '#aed6ff', // 设置 Y 轴名称的字体颜色
              fontSize:14
            },
            axisLabel: {
              textStyle: {
                color: '#aed6ff' // 
              }
            },
            ameTextStyle: {
              color: '#aed6ff'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',//
                color: "#02435E"
              },
            },
          },
          series: [
            {
              name: '告警',
              type: 'bar',
              barWidth: '15',
              data: values,
            },
          ]
        }
        pieChart.setOption(option);
      })
    },

  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

#alarm-analysis {
  width: 100%;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}

.main {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.content {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.query-form {
  display: flex;
}

/* tabs */
.tabs {
  background-color: rgb(4, 25, 66);
  display: flex;
}

.tabs li {
  height: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
  font-size: 14px;
  font-family: Microsoft YaHei;
  color: #e8f4ff;
  text-align: center;
  padding: 0 20px;
  line-height: 40px;
}

.tabs .select {
  background-color: #002c6c;
  color: #ffc74a;
}

.query-button {
  margin-top: 6px;
}

.alarm-info {
  height: 84px;
  padding: 0px 22px;
  display: flex;
  align-items: center;
  background-color: #061c45;
  box-sizing: border-box;
}

.alarm-info li {
  width: 33.3%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alarm-info .circle {
  width: 42px;
  height: 42px;
  border: 2px solid #ef954e;
  border-radius: 50%;
  font-size: 22px;
  color: #ef954e;
  text-align: center;
  line-height: 42px;
  margin-right: 5px;
}

.alarm-number {
  height: 50px;
  width: 25%;
}

.alarm-number div {
  color: #e8f4ff;
  font-size: 12px;
  line-height: 26px;
  text-align: left;
}

.alarm-number span {
  color: #e8f4ff;
  font-size: 24px;
  line-height: 26px;
  display: block;
  text-align: left;
  font-weight: 700;
}

.chain div {
  color: #e8f4ff;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
}

.chain p {
  color: #97fc9f;
  font-size: 13px;
  line-height: 26px;
  text-align: center;
}

.alarm-total {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alarm-total p {
  color: #aed6ff;
  font-size: 16px;
}

.alarm-total span {
  color: #ffc74a;
}

.alarm-total img {
  width: 14px;
  height: 14px;
  margin-left: 6px;
}

.chart-title {
  width: 207px;
  height: 33px;
  border-radius: 66px;
  background-color: rgba(0, 44, 108, 0.73);
  line-height: 33px;
  text-align: center;
  font-size: 14px;
  color: #aed6ff;
  margin: 12px 0 0 17px;
}

.content-left,
.content-right {
  width: 49.5%;
}

.content-right {
  background: #06183c;
}

#pieChart {
  height: 400px;
  width: 100%;
}

#chart {
  width: 245px;
  height: 198px;
  position: relative;
  top: 15px;
}

.monthTitleText {
  margin: 10px;
  /* padding-bottom: 15px; */
}

#monthCharts {
  width: 98%;
  height: 500px;
  /* border: 1px solid #fff; */
  margin: 0  10px;
}

.abcccc >>> .el-picker-panel{
position: absolute;
right: 60px !important;
}
</style>
